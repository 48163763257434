import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { ScheduleReport, ScheduleReportUpdate } from '@/App/Services/interfaces/scheduleReport.interface'
import { ScheduleReportService } from '@/App/Services/ScheduleReport.service'

const scheduleReport = namespace('ScheduleReport')
const sharedStore = namespace('Shared')

@Component
export default class ReportsList extends Vue {
  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @scheduleReport.Mutation setScheduleReports: (report: ScheduleReport[]) => void
  @scheduleReport.Mutation updateScheduleReport: (report: ScheduleReportUpdate) => void
  @scheduleReport.Mutation deleteScheduleReport: (id: string) => void
  @scheduleReport.State scheduleReports: ScheduleReport[]
  @sharedStore.Mutation setNotification: (notification: Notification) => void
  public isLoading = false
  public search = ''

  public async onStatusChange(id: string, status: boolean) {
    try {
      this.isLoading = true

      await ScheduleReportService.update(id, { isEnable: status })
      this.updateScheduleReport({ _id: id, isEnable: status })

      this.setNotification({ text: this.$t('scheduleReportsPage.reportsList.reportStatusChangedNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async onDelete(id: string) {
    try {
      const title = this.$t('scheduleReportsPage.reportsList.deleteScheduleReportConfirmTitle').toString()
      const text = this.$t('scheduleReportsPage.reportsList.deleteScheduleReportConfirmText').toString()

      if (await this.confirm({ title, text })) {
        this.isLoading = true

        const deletedId = await ScheduleReportService.destroy(id)
        this.deleteScheduleReport(deletedId)

        this.setNotification({ text: this.$t('scheduleReportsPage.reportsList.reportDeletedNotificationText').toString() })
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  private async mounted() {
    try {
      this.isLoading = true

      const reports = await ScheduleReportService.fetch()
      this.setScheduleReports(reports)

    } catch {} finally {
      this.isLoading = false
    }
  }
}
