import { VuetifySelect } from '@/interfaces/vuetify'
import { ReportTimeInterval, ReportType } from '@/types'
import i18n from '@/plugins/i18n'

export const REPORT_TYPES_ITEMS: VuetifySelect[] = [
  { value: 'consumption', text: i18n.tc('reportsPage.reportSettingsDialog.constants.consumptionText') },
  { value: 'digitalSensor', text: i18n.tc('reportsPage.reportSettingsDialog.constants.digitalSensorText') },
  { value: 'doorSensor', text: i18n.tc('reportsPage.reportSettingsDialog.constants.doorSensorText') },
  { value: 'summaryInformation', text: i18n.tc('reportsPage.reportSettingsDialog.constants.sensorSummaryText') },
  { value: 'sensorTracing', text: i18n.tc('reportsPage.reportSettingsDialog.constants.sensorTracingText') },
  { value: 'notificationLog', text: i18n.tc('reportsPage.reportSettingsDialog.constants.notificationLogText') },
  { value: 'energyConsumption', text: i18n.tc('reportsPage.reportSettingsDialog.constants.energyConsumptionText') },
  { value: 'AIThermalReportDetailed', text: i18n.tc('reportsPage.reportSettingsDialog.constants.aiThermalReportDetailedText') },
  { value: 'journey', text: i18n.tc('reportsPage.reportSettingsDialog.constants.journeyText') },
  { value: 'geofences', text: i18n.tc('reportsPage.reportSettingsDialog.constants.geofenceText') },
  { value: 'stops', text: i18n.tc('reportsPage.reportSettingsDialog.constants.stopsText') },
  { value: 'fuel', text: i18n.tc('reportsPage.reportSettingsDialog.constants.fuelReportsText') },
  { value: 'parking', text: i18n.tc('reportsPage.reportSettingsDialog.constants.parkingText') },
  { value: 'lastSensorValue', text: i18n.tc('reportsPage.reportSettingsDialog.constants.lastSensorValueText') }
]

export const TIME_INTERVAL_ITEMS: VuetifySelect[] = [
  { value: 'today', text: i18n.tc('reportsPage.reportSettingsDialog.constants.todayText') },
  { value: 'yesterday', text: i18n.tc('reportsPage.reportSettingsDialog.constants.yesterdayText') },
  { value: 'week', text: i18n.tc('reportsPage.reportSettingsDialog.constants.weekText') },
  { value: 'custom', text: i18n.tc('reportsPage.reportSettingsDialog.constants.customText') }
]

export const REPORT_DEFAULT: ReportSettings = {
  type: 'digitalSensor',
  timeInterval: 'today',
  devicesIds: [],
  deviceId: 0,
  dashboardId: '',
  widgetsIds: [],
  dateFrom: 0,
  dateTo: 0,
  dataInterval: 0,
  geofencesIds: [],
  minDuration: 5,
  calculatorId : 0
}

interface ReportSettings {
  type: ReportType
  timeInterval: ReportTimeInterval
  devicesIds: number[]
  deviceId: number
  dashboardId: string
  widgetsIds: string[]
  dateFrom: number
  dateTo: number
  dataInterval: number
  geofencesIds: string[]
  minDuration: number
  calculatorId: number
}
