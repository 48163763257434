import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import { VuetifySelect } from '@/interfaces/vuetify'
import { ReportType } from '@/types'
import { REPORT_TYPES_ITEMS } from '@/App/Pages/Reports/ReportSettingsDialog/constants'

@Component
export default class ReportTypeSelect extends Mixins(Validator) {
  @Prop({ default: '' }) readonly value: ReportType
  public type: ReportType = 'digitalSensor'
  public reportTypesItems: VuetifySelect[] = REPORT_TYPES_ITEMS

  @Watch('value')
  valueChanged(val: ReportType) {
    this.type = val
  }
}
