import axios from 'axios'

export function removeKeysFromObject<T>(keys: string[], obj: any) {
  keys.forEach(key => {
    Object.keys(obj).forEach(k => {
      if (key === k) {
        delete obj[k]
      }
    })
  })

  return obj
}

export async function getLocationAddress(lat: number, lon: number): Promise<string> {
  try {
    const { data } = await axios.get('https://nominatim.openstreetmap.org/reverse', {
      params: { lat, lon, format: 'json' }
    })

    return data.display_name
  } catch (e) {
    throw e
  }
}
