import { Component, Vue } from 'vue-property-decorator'
import ReportsList from './ReportsList/Reports-List.vue'
import ReportForm from './ReportForm/Report-Form.vue'
import { ScheduleReport } from '@/App/Services/interfaces/scheduleReport.interface'

@Component({
  components: { ReportsList, ReportForm }
})
export default class ScheduleReportsPage extends Vue {
  public isFormShow = false

  public onEdit(report: ScheduleReport) {
    // @ts-ignore
    this.$refs.reportForm.onEdit(report)
    this.isFormShow = true
  }
}
