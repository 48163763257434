import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { removeKeysFromObject } from '@/App/Utils/utils'
import ReportTypeSelect from './ReportTypeSelect/Report-Type-Select.vue'
import SelectDashboard from '@/App/Components/DashboardSelect/Dashboard-Select.vue'
import WidgetSelect from '@/App/Components/WidgetSelect/Widget-Select.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import DayOfWeekSelect from '@/App/Components/DayOfWeekSelect/Day-Of-Week-Select.vue'
import LocationDeviceSelect from '@/App/Components/LocationDeviceSelect/Location-Device-Select.vue'
import GeofenceSelect from '@/App/Components/GeofenceSelect/Geofence-Select.vue'
import { DayOfWeek, ReportType } from '@/types'
import { VuetifySelect } from '@/interfaces/vuetify'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { ScheduleReport, ScheduleReportUpdate } from '@/App/Services/interfaces/scheduleReport.interface'
import { ScheduleReportService } from '@/App/Services/ScheduleReport.service'

const sharedStore = namespace('Shared')
const scheduleReport = namespace('ScheduleReport')

@Component({
  components: { ReportTypeSelect, SelectDashboard, WidgetSelect, DeviceSelect, DayOfWeekSelect, LocationDeviceSelect, GeofenceSelect }
})
export default class ReportForm extends Mixins(Validator) {
  @scheduleReport.Mutation addScheduleReport: (report: ScheduleReport) => void
  @scheduleReport.Mutation updateScheduleReport: (report: ScheduleReportUpdate) => void
  @sharedStore.Mutation setNotification: (notification: Notification) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  public isLoading = true
  public timeIntervalItems: VuetifySelect[] = [
    { value: 'today', text: this.$t('scheduleReportsPage.reportForm.todayText').toString() },
    { value: 'yesterday', text: this.$t('scheduleReportsPage.reportForm.yesterdayText').toString() },
    { value: 'week', text: this.$t('scheduleReportsPage.reportForm.weekText').toString() },
    { value: 'previous', text: this.$t('scheduleReportsPage.reportForm.previousText').toString() }
  ]
  // TODO replace this interface on interface from service
  public report: Report = {
    _id: '',
    name: '',
    type: 'digitalSensor',
    devicesIds: [],
    localDevicesIds: [],
    dashboardId: '',
    widgetsIds: [],
    deviceId: 0,
    calculatorId: 0,
    geofencesIds: [],
    exportType: 'excel',
    interval: 'today',
    timeInterval: '01:30',
    emails: [],
    executionType: 'On schedule',
    executionTime: '12:00',
    daysOfWeek: [],
    minDuration: 5
  }
  public form = false

  private resetForm() {
    this.report = {
      _id: '',
      name: '',
      type: 'digitalSensor',
      devicesIds: [],
      localDevicesIds: [],
      dashboardId: '',
      widgetsIds: [],
      geofencesIds: [],
      exportType: 'excel',
      interval: 'today',
      timeInterval: '01:30',
      emails: [],
      executionType: 'On schedule',
      executionTime: '12:00',
      daysOfWeek: [],
      minDuration: 5,
      calculatorId: 0,
      deviceId: 0
    }

    // @ts-ignore
    this.$refs.form.resetValidation()
  }

  public async onSave() {
    try {
      this.isLoading = true

      const report = await ScheduleReportService.store(this.prepareReportData())
      this.addScheduleReport(report)

      this.setNotification({ text: this.$t('scheduleReportsPage.reportForm.reportStoredNotificationText').toString() })

      this.$emit('onClose')
      this.resetForm()
    } catch {} finally {
      this.isLoading = false
    }
  }

  public onEdit(report: Report) {
    this.report = {
      ...report,
      timeInterval: report.timeInterval || '01:30'
    }
  }

  public async onUpdate() {
    try {
      this.isLoading = true

      const report = await ScheduleReportService.update(this.report._id, this.prepareReportData())
      this.updateScheduleReport(report)

      this.setNotification({ text: this.$t('scheduleReportsPage.reportForm.reportUpdatedNotificationText').toString() })

      this.$emit('onClose')
      this.resetForm()
    } catch {} finally {
      this.isLoading = false
    }
  }

  async onClose(type: 'create' | 'update') {
    const title = `Finish ${type === 'create' 
      ? this.$t('scheduleReportsPage.reportForm.confirmReportText1') 
      : this.$t('scheduleReportsPage.reportForm.updateText')} report?`
    const text = `
        Are you sure?<br/>
        ${type === 'create' 
      ? this.$t('scheduleReportsPage.reportForm.confirmReportText2') 
      : this.$t('scheduleReportsPage.reportForm.confirmReportText3')}.
    `
    // @ts-ignore
    if (await this.confirm({ title, text })) {
      this.$emit('onClose')
      this.resetForm()
    }
  }
  /**
   * Prepare data for action on server, deleted unused fields for selected report type
   */
  private prepareReportData(): Report {
    let report: Report = { ...this.report }

    if (['notificationLog', 'AIThermalReportDetailed', 'journey', 'geofences', 'stops', 'parking'].includes(report.type!)) {
      report = removeKeysFromObject([
        'dashboardId',
        'widgetsIds'
      ], report)
    } else {
      report = removeKeysFromObject([
        'devicesIds',
        'localDevicesIds'
      ], report)
    }

    if (report.interval !== 'previous') {
      delete report.timeInterval
    }
    //TODO remove this field from this form on edit
    // @ts-ignore
    delete report.isEnable
    delete report._id

    return report
  }

  get isSelectDashboardShow(): boolean {
    const exclude: ReportType[] = ['notificationLog', 'AIThermalReportDetailed', 'journey', 'geofences', 'stops', 'parking', 'fuel', 'lastSensorValue']
    return !exclude.includes(this.report.type)
  }
}

interface Report {
  _id: string
  name: string
  type: ReportType
  devicesIds?: number[]
  localDevicesIds?: number[]
  dashboardId?: string
  widgetsIds?: string[]
  geofencesIds?: string[]
  minDuration?: number
  exportType: 'excel' | 'pdf'
  interval: 'today' | 'yesterday' | 'week' | 'previous'
  timeInterval?: string
  emails: string[]
  executionType: 'Each (every)' | 'On schedule'
  executionTime: string
  daysOfWeek: DayOfWeek[]
  calculatorId: number
  deviceId: number
}
