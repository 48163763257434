import { HTTP } from '@/App/Utils/axios'
import { ScheduleReport, ScheduleReportStore, ScheduleReportUpdate } from './interfaces/scheduleReport.interface'

export class ScheduleReportService {
  static async fetch(): Promise<ScheduleReport[]> {
    try {
      const { data } = await HTTP.get('/schedule-reports')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(report: ScheduleReportStore): Promise<ScheduleReport> {
    try {
      const { data } = await HTTP.post('/schedule-reports', report)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, report: ScheduleReportUpdate): Promise<ScheduleReport> {
    try {
      const { data } = await HTTP.put(`/schedule-reports/${id}`, report)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/schedule-reports/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }
}
