import { HTTP } from '@/App/Utils/axios'
import { IDashboard, StoreDashboard } from '@/App/Services/interfaces/dashboard.interface'

export class DashboardService {
  static async fetch(): Promise<IDashboard[]> {
    try {
      const { data } = await HTTP.get('/dashboards/')

      return data
    } catch (e) {
      throw e
    }
  }

  static async getById(id: string): Promise<IDashboard> {
    try {
      const { data } = await HTTP.get(`/dashboards/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(dashboard: StoreDashboard): Promise<IDashboard> {
    try {
      const { data } = await HTTP.post('/dashboards/', dashboard)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, dashboard: Partial<StoreDashboard>): Promise<IDashboard> {
    try {
      const { data } = await HTTP.patch(`/dashboards/${id}`, dashboard)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/dashboards/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async share(dashboardId: string, userId: string): Promise<void> {
    try {
      await HTTP.put(`/dashboards/${dashboardId}/share/user/${userId}`)
    } catch (e) {
      throw e
    }
  }

  static async unshared(userId: string, dashboardId: string): Promise<void> {
    try {
      await HTTP.delete(`/dashboards/${dashboardId}/share/user/${userId}`)
    } catch (e) {
      throw e
    }
  }

  static async generate(dashboardId: string, deviceId: number, dashboardTemplateId: string): Promise<any[]> {
    try {

     const { data } = await HTTP.post(`dashboard/${dashboardId}/widgets/create-from-template`, { deviceId, dashboardTemplateId })

      return data
    } catch (e) {
      throw e
    }
  }

}
