import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { DayOfWeek } from '@/types'

@Component
export default class SensorSelect extends Vue {
  @Prop({ default: '' }) readonly value: string
  public daysOfWeek: DayOfWeek[] = []
  public daysOfWeekItems: DayOfWeek[] = this.$t('dayOfWeekSelectComponent.daysOfWeek').toString().split(',') as DayOfWeek[]

  @Watch('value')
  valueChanged(val: DayOfWeek[]) {
    this.daysOfWeek = val
  }
}
